import { css } from "@emotion/react";

export const baseFont = "'Roboto', sans-serif";

export const scale = process.env.GATSBY_DISPLAY_SCALE;

export const scaledSize = (num) => Math.floor(num * 0.333);

export const fontWeights = {
  black: 900,
  bold: 700,
  // semiBold: 600,
  medium: 500,
  normal: 400,
  thin: 300,
};

export const percent = (n) => `${Math.floor(n * 100)}%`;

export const percentF = (n) => `${n.toFixed(2) * 100}%`;

export const rem = (px) => `${scaledSize(px) / 16}rem`;

export const fontSize = (px, lineHeight) => css`
  font-size: ${rem(px)};
  line-height: ${(lineHeight && lineHeight / px) || "1.2"};
`;

export const maxWidthpx = 1160;
export const maxWidth = rem(1160);

export const white = "#fff";
export const black = "#000";
export const ivory = "#CFC8B7";
export const gray10 = "#101010";
export const gray1F = "#1F1F1F";
export const signal = "#F14A32";

export const colors = {
  white,
  black,
  ivory,
  gray10,
  gray1F,
  signal,
  textPrimary: gray10,
};

export const baseList = css`
  list-style: none;
  padding: 0;
  margin: 0;
`;

export const baseText = css`
  font-size: 18px;
  line-height: 1.625;
`;

export const block = css`
  display: block;
`;

export const labelText = css`
  font-size: 21px;
  line-height: ${30 / 21};
`;

export const weightBold = css`
  font-weight: ${fontWeights.bold};
`;

export const weightThin = css`
  font-weight: ${fontWeights.thin};
`;

export const iconSquare = css`
  width: 20px;
  height: 20px;
`;

export const pinAll = css`
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
`;

export const border = css`
  border: 1px solid var(--ivory);
`;

export const borderRound = css`
  border-radius: 6px;
`;

export const textButton = css`
  border-color: transparent;
  background-color: transparent;
  color: inherit;
  cursor: pointer;
`;

export const pinTop = css`
  top: 0;
`;

export const pinTopGap = css`
  top: var(--top-gap);
`;

export const cover = css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const contain = css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

export const square = css`
  position: relative;
  height: 0;
  padding-top: 100%;
`;

export const flexCenterAll = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const flex = css`
  display: flex;
`;

export const flexInline = css`
  display: inline-flex;
`;

export const justifyStart = css`
  justify-content: flex-start;
`;

export const justifyCenter = css`
  justify-content: center;
`;

export const justifyBetween = css`
  justify-content: space-between;
`;

export const alignStart = css`
  align-items: flex-start;
`;

export const alignCenter = css`
  align-items: center;
`;

export const flexCol = css`
  flex-direction: column;
`;

export const container = css`
  max-width: calc(var(--container-max-width));
  min-width: calc(var(--container-min-width));
  padding-left: var(--gutter);
  padding-right: var(--gutter);
`;

export const gutters = css`
  padding-right: var(--gutter);
  padding-left: var(--gutter);
`;

export const contentWidth = 4664;

export const relative = css`
  position: relative;
`;

export const absolute = css`
  position: absolute;
`;

export const overflowHidden = css`
  overflow: hidden;
`;

export const mt0 = css`
  margin-top: 0;
`;

export const mtauto = css`
  margin-top: auto;
`;

export const my0 = css`
  margin-top: 0;
  margin-bottom: 0;
`;

export const mx0 = css`
  margin-left: 0;
  margin-right: 0;
`;

export const mxauto = css`
  margin-left: auto;
  margin-right: auto;
`;

export const mlauto = css`
  margin-left: auto;
`;

export const pTopGap = css`
  padding-top: var(--top-gap);
`;

export const p8 = css`
  padding: 32px;
`;

export const mb6 = css`
  margin-bottom: 24px;
`;

export const mrauto = css`
  margin-right: auto;
`;

export const w100 = css`
  width: 100%;
`;

export const w50 = css`
  width: 50%;
`;

export const z100 = css`
  z-index: 100;
`;

export const hexToRGBA = (hex, a = 1) => {
  const value = parseInt(hex.replace(/^#/, ""), 16);
  const r = (value >> 16) & 255;
  const g = (value >> 8) & 255;
  const b = value & 255;
  return `rgba(${r},${g},${b},${a})`;
};

export const invertColors = css`
  html {
    color: ${gray1F};
    background: ${ivory};
  }

  :root {
    --scroll-track: ${hexToRGBA(gray1F, 0.06)};
    --graydark: ${ivory};
    --ivory: ${gray1F};
  }

  .invertColors {
    --graydark: ${ivory};
    --ivory: ${gray1F};
  }
`;

// export const debugCenterLine = css`
//   &::after {
//     content: "";
//     position: fixed;
//     top: 0;
//     bottom: 0;
//     left: 50%;
//     display: block;
//     width: 1px;
//     background: red;
//     z-index: 20000;
//   }
// `;
